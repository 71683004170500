@tailwind base;
@tailwind utilities;
@tailwind components;

@import 'styles/components/components.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.suggestion-popup {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
  width: fit-content;
}

.suggestion-popup ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.suggestion-popup li {
  padding: 8px 16px;
  cursor: pointer;
}

.suggestion-popup li:hover {
  background-color: #f1f1f1;
}
